import {HttpContext, HttpContextToken} from '@angular/common/http';
import {ClientErrorCode, CustomErrorHandlingHttpContextModel} from './declarations';

export const CUSTOM_ERROR_HANDLING = new HttpContextToken<CustomErrorHandlingHttpContextModel>(() => null);

export const setCustomErrorHandlingContext =
  (model: CustomErrorHandlingHttpContextModel) =>
  (context = new HttpContext()): HttpContext =>
    context.set(CUSTOM_ERROR_HANDLING, model);

export const getSingleErrorHttpContextModel = (errorCode: ClientErrorCode): CustomErrorHandlingHttpContextModel => ({
  singleError: {
    errorCode,
  },
});

export const setSingleErrorCustomErrorHandlingContext = (errorCode: ClientErrorCode) =>
  setCustomErrorHandlingContext(getSingleErrorHttpContextModel(errorCode));
