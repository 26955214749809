export enum ClientErrorCode {
  AcknowledgeDisclaimer = 'acknowledge-disclaimer',
  AddingOPLSource = 'adding-opl-source',
  ChangingOplSourcesOrder = 'changing-opl-sources-order',
  CheckingForDuplicates = 'checking-for-duplicates',
  CreatingAntibodyProfiles = 'creating-antibody-profiles',
  CreatingOPL = 'creating-opl',
  CreatingOplComments = 'creating-opl-comments',
  CreatingOrUpdatingSSARequests = 'creating-updating-ssa-requests',
  CreatingPatients = 'creating-patients',
  Default = 'default',
  DeletingOPL = 'deleting-opl',
  DeletingOPLSources = 'deleting-opl-sources',
  DeletingOplComments = 'deleting-opl-comments',
  DeletingPatientInProgress = 'deleting-patient-in-progress',
  DownloadingDocuments = 'downloading-documents',
  EditingOPL = 'editing-opl',
  EditingOplComments = 'updating-opl-comments',
  LoadingAnnouncements = 'loading-announcements',
  LoadingAntibodySpecificiesDocuments = 'loading-antibody-specificies-documents',
  LoadingAntibodySpecInfo = 'loading-antibody-spec-information',
  LoadingBLeaderData = 'loading-b-leader-data',
  LoadingCenterInfo = 'loading-center-info',
  LoadingCaseRelatedInformation = 'loading-case-related-info',
  LoadingCordData = 'loading-cord-data',
  LoadingDPB1Data = 'loading-dpb1-data',
  LoadingDisclaimer = 'loading-disclaimer',
  LoadingDocuments = 'loading-documents',
  LoadingDocumentsExtensions = 'loading-documents-extensions',
  LoadingDonorData = 'loading-donor-data',
  LoadingDonorVaccinationData = 'loading-donor-vaccination-data',
  LoadingDsaData = 'loading-dsa-data',
  LoadingDuplicatePatientHistoryData = 'loading-duplicate-patient-history-data',
  LoadingGeography = 'loading-geography',
  LoadingHlaMismatchData = 'loading-hla-mismatch-data',
  LoadingHlaTypingData = 'loading-hla-typing-data',
  LoadingLabReports = 'loading-lab-reports',
  LoadingMatchCategoryData = 'loading-match-category-data',
  LoadingMatchingInfo = 'loading-matching-information',
  LoadingOPLs = 'loading-opls',
  LoadingOplComments = 'loading-opl-comments',
  LoadingOrderData = 'loading-order-data',
  LoadingKitOrderDataLabRefIds = 'loading-kit-order-data-lab-ref-ids',
  LoadingOrderDetails = 'loading-order-details',
  LoadingPatientAntibodyData = 'loading-patient-antibody-data',
  LoadingPatientData = 'loading-patient-data',
  LoadingPatientDocuments = 'loading-all-documents',
  LoadingPatientIdInfo = 'loading-patient-id-info',
  LoadingPatientOrderData = 'loading-patient-order-data',
  LoadingPatientWmdaData = 'loading-patient-wmda-data',
  LoadingSearches = 'loading-searches',
  LoadingSearchSummaryReports = 'loading-search-summary-reports',
  LoadingSecondaryCodes = 'loading-secondary-codes',
  LoadingSourceData = 'loading-source-data',
  LoadingSSADonorMatchGrades = 'loading-ssa-donor-match-grades',
  LoadingSSALabReports = 'loading-ssa-lab-reports',
  LoadingSsaRequests = 'loading-ssa-requests',
  LoadingSubjectData = 'loading-subject-data',
  LoadingReferenceData = 'loading-reference-data',
  LoadingTcCoordinatorData = 'loading-tc-coordinator-data',
  LoadingUserSettings = 'loading-user-settings',
  LoadingWMDAData = 'loading-wmda-data',
  ModifyingOPLSource = 'modifying-opl-source',
  LoadingNotifications = 'loading-notifications',
  PreparingExports = 'preparing-exports',
  PreparingUnitReports = 'preparing-unit-reports',
  ResolvingDuplicates = 'resolving-duplicates',
  RunningSearches = 'running-searches',
  SavingOPLs = 'saving-opls',
  SavingPatientStatus = 'saving-patient-status',
  SavingUserSettings = 'saving-user-settings',
  UpdatingPatient = 'updating-patients',
  ValidatingHlaTyping = 'validating-hla-typing',
  LoadingPatientSwabKitOrders = 'load-patient-swab-kit-orders',
  LoadingBulkSwabKitOrders = 'load-bulk-swab-kit-orders',
  LoadingAddressSuggestions = 'loading-address-suggestions',
  ValidatingAddress = 'validating-address',
  CreateBulkSwabKitOrder = 'create-bulk-swab-kit-order',
  LoadAddressContactsBulkSwabKitOrder = 'load-address-contacts-bulk-swab-kit-order',
  LoadCenterIdsAddressesBulkSwabKitOrder = 'load-center-ids-address-bulk-swab-kit-order',
  ProcessingYourDocuments = 'processing-your-documents',
  CreatingCoopData = 'creating-coop-data',
  UpdatingCoopData = 'updating-coop-data',
  CreatingUpdatingCoopData = 'creating-updating-donor-data',
  LoadingProgramData = 'loading-program-data',
  CreatingUpdatingSearchSummaryReport = 'creating-updating-search-summary-report',
  LoadEssentialAppData = 'LoadEssentialAppData',
  LoadRaceEthnicityHistory = 'load-race-ethnicity-history',
}

export const ERROR_MESSAGE_PATTERNS: Record<ClientErrorCode, string> = {
  [ClientErrorCode.AcknowledgeDisclaimer]: 'ERRORS.TECHNICAL_DIFFICULTIES.SAVING_DISCLAIMER',
  [ClientErrorCode.AddingOPLSource]: 'ERRORS.TECHNICAL_DIFFICULTIES.ADDING_OPL_SOURCE',
  [ClientErrorCode.ChangingOplSourcesOrder]: 'ERRORS.TECHNICAL_DIFFICULTIES.CHANGING_OPL_SOURCES_ORDER',
  [ClientErrorCode.CheckingForDuplicates]: 'ERRORS.TECHNICAL_DIFFICULTIES.CHECKING_FOR_DUPLICATES',
  [ClientErrorCode.CreatingAntibodyProfiles]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_ANTIBODY_PROFILES',
  [ClientErrorCode.CreatingOPL]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_OPL',
  [ClientErrorCode.CreatingOplComments]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_OPL_COMMENTS',
  [ClientErrorCode.CreatingPatients]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_PATIENTS',
  [ClientErrorCode.CreatingOrUpdatingSSARequests]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_UPDATING_SSA_REQUESTS',
  [ClientErrorCode.Default]: 'ERRORS.TECHNICAL_DIFFICULTIES.DEFAULT',
  [ClientErrorCode.DeletingOPLSources]: 'ERRORS.TECHNICAL_DIFFICULTIES.DELETING_OPL_SOURCES',
  [ClientErrorCode.DeletingOPL]: 'ERRORS.TECHNICAL_DIFFICULTIES.DELETING_OPL',
  [ClientErrorCode.DeletingOplComments]: 'ERRORS.TECHNICAL_DIFFICULTIES.DELETING_OPL_COMMENTS',
  [ClientErrorCode.DeletingPatientInProgress]: 'ERRORS.TECHNICAL_DIFFICULTIES.DELETING_PATIENT_IN_PROGRESS',
  [ClientErrorCode.DownloadingDocuments]: 'ERRORS.TECHNICAL_DIFFICULTIES.DOWNLOADING_DOCUMENTS',
  [ClientErrorCode.EditingOPL]: 'ERRORS.TECHNICAL_DIFFICULTIES.EDITING_OPL',
  [ClientErrorCode.EditingOplComments]: 'ERRORS.TECHNICAL_DIFFICULTIES.EDITING_OPL_COMMENTS',
  [ClientErrorCode.LoadingAnnouncements]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_ANNOUNCEMENTS',
  [ClientErrorCode.LoadingAntibodySpecificiesDocuments]:
    'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_ANTIBODY_SPECIFICIES_DOCUMENTS',
  [ClientErrorCode.LoadingAntibodySpecInfo]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_ANTIBODY_SPEC_INFO',
  [ClientErrorCode.LoadingBLeaderData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_B_LEADER_DATA',
  [ClientErrorCode.LoadingCenterInfo]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_CENTER_INFO',
  [ClientErrorCode.LoadingCaseRelatedInformation]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_CASE_RELATED_INFORMATION',
  [ClientErrorCode.LoadingCordData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_CORD_DATA',
  [ClientErrorCode.LoadingDPB1Data]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DPB1_DATA',
  [ClientErrorCode.LoadingDisclaimer]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DISCLAIMER',
  [ClientErrorCode.LoadingDocuments]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DOCUMENTS',
  [ClientErrorCode.LoadingDocumentsExtensions]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DOCUMENTS_EXTENSIONS',
  [ClientErrorCode.LoadingDonorData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DONOR_DATA',
  [ClientErrorCode.LoadingDonorVaccinationData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DONOR_VACCINATION_DATA',
  [ClientErrorCode.LoadingDsaData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DSA_DATA',
  [ClientErrorCode.LoadingDuplicatePatientHistoryData]:
    'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_DUPLICATE_PATIENT_HISTORY_DATA',
  [ClientErrorCode.LoadingGeography]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_GEOGRAPHY',
  [ClientErrorCode.LoadingHlaMismatchData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_HLA_MISMATCH_DATA',
  [ClientErrorCode.LoadingHlaTypingData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_HLA_TYPING_DATA',
  [ClientErrorCode.LoadingLabReports]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_LAB_REPORTS',
  [ClientErrorCode.LoadingMatchCategoryData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_MATCH_CATEGORY_DATA',
  [ClientErrorCode.LoadingMatchingInfo]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_MATCHING_INFO',
  [ClientErrorCode.LoadingNotifications]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_NOTIFICATIONS',
  [ClientErrorCode.LoadingOPLs]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_OPLS',
  [ClientErrorCode.LoadingOplComments]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_OPL_COMMENTS',
  [ClientErrorCode.LoadingOrderData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_ORDER_DATA',
  [ClientErrorCode.LoadingKitOrderDataLabRefIds]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_KIT_ORDER_DATA_LAB_REF_IDS',
  [ClientErrorCode.LoadingOrderDetails]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_ORDER_DETAILS',
  [ClientErrorCode.LoadingPatientAntibodyData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_ANTIBODY_DATA',
  [ClientErrorCode.LoadingPatientData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_DATA',
  [ClientErrorCode.LoadingPatientDocuments]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_DOCUMENTS',
  [ClientErrorCode.LoadingPatientIdInfo]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_ID_INFO',
  [ClientErrorCode.LoadingPatientOrderData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_ORDER_DATA',
  [ClientErrorCode.LoadingPatientWmdaData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_WMDA_DATA',
  [ClientErrorCode.LoadingSearches]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SEARCHES',
  [ClientErrorCode.LoadingSearchSummaryReports]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SEARCH_SUMMARY_REPORTS',
  [ClientErrorCode.LoadingSecondaryCodes]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SECONDARY_CODES',
  [ClientErrorCode.LoadingSourceData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SOURCE_DATA',
  [ClientErrorCode.LoadingSSADonorMatchGrades]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SSA_DONOR_MATCH_GRADES',
  [ClientErrorCode.LoadingSSALabReports]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SSA_LAB_REPORTS',
  [ClientErrorCode.LoadingSsaRequests]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SSA_REQUESTS',
  [ClientErrorCode.LoadingSubjectData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_SUBJECT_DATA',
  [ClientErrorCode.LoadingReferenceData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_REFERENCE_DATA',
  [ClientErrorCode.LoadingTcCoordinatorData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_TC_COORDINATOR_DATA',
  [ClientErrorCode.LoadingUserSettings]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_USER_SETTINGS',
  [ClientErrorCode.LoadingWMDAData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_WMDA_DATA',
  [ClientErrorCode.ModifyingOPLSource]: 'ERRORS.TECHNICAL_DIFFICULTIES.MODIFYING_OPL_SOURCE',
  [ClientErrorCode.PreparingExports]: 'ERRORS.TECHNICAL_DIFFICULTIES.PREPARING_EXPORTS',
  [ClientErrorCode.PreparingUnitReports]: 'ERRORS.TECHNICAL_DIFFICULTIES.PREPARING_UNIT_REPORTS',
  [ClientErrorCode.ResolvingDuplicates]: 'ERRORS.TECHNICAL_DIFFICULTIES.RESOLVING_DUPLICATES',
  [ClientErrorCode.RunningSearches]: 'ERRORS.TECHNICAL_DIFFICULTIES.RUNNING_SEARCHES',
  [ClientErrorCode.SavingOPLs]: 'ERRORS.TECHNICAL_DIFFICULTIES.SAVING_OPLS',
  [ClientErrorCode.SavingPatientStatus]: 'ERRORS.TECHNICAL_DIFFICULTIES.SAVING_PATIENT_STATUS',
  [ClientErrorCode.SavingUserSettings]: 'ERRORS.TECHNICAL_DIFFICULTIES.SAVING_USER_SETTINGS',
  [ClientErrorCode.UpdatingPatient]: 'ERRORS.TECHNICAL_DIFFICULTIES.UPDATING_PATIENT',
  [ClientErrorCode.ValidatingHlaTyping]: 'ERRORS.TECHNICAL_DIFFICULTIES.VALIDATING_HLA_TYPING',
  [ClientErrorCode.LoadingPatientSwabKitOrders]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PATIENT_SWAB_KIT_ORDERS',
  [ClientErrorCode.LoadingBulkSwabKitOrders]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_BULK_SWAB_KIT_ORDERS',
  [ClientErrorCode.LoadingAddressSuggestions]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_ADDRESS_SUGGESTIONS',
  [ClientErrorCode.ValidatingAddress]: 'ERRORS.TECHNICAL_DIFFICULTIES.VALIDATING_ADDRESS',
  [ClientErrorCode.CreateBulkSwabKitOrder]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATE_BULK_SWAB_KIT_ORDER',
  [ClientErrorCode.LoadAddressContactsBulkSwabKitOrder]:
    'ERRORS.TECHNICAL_DIFFICULTIES.LOAD_ADDRESS_CONTACTS_BULK_SWAB_KIT_ORDER',
  [ClientErrorCode.LoadCenterIdsAddressesBulkSwabKitOrder]:
    'ERRORS.TECHNICAL_DIFFICULTIES.LOAD_CENTER_IDS_ADDRESSES_BULK_SWAB_KIT_ORDER',
  [ClientErrorCode.ProcessingYourDocuments]: 'ERRORS.TECHNICAL_DIFFICULTIES.PROCESSING_YOUR_DOCUMENTS',
  [ClientErrorCode.CreatingCoopData]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_COOP_DATA',
  [ClientErrorCode.UpdatingCoopData]: 'ERRORS.TECHNICAL_DIFFICULTIES.UPDATING_COOP_DATA',
  [ClientErrorCode.CreatingUpdatingCoopData]: 'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_UPDATING_COOP_DATA',
  [ClientErrorCode.LoadingProgramData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOADING_PROGRAM_DATA',
  [ClientErrorCode.CreatingUpdatingSearchSummaryReport]:
    'ERRORS.TECHNICAL_DIFFICULTIES.CREATING_UPDATING_SEARCH_SUMMARY_REPORT',
  [ClientErrorCode.LoadEssentialAppData]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOAD_ESSENTIAL_APP_DATA',
  [ClientErrorCode.LoadRaceEthnicityHistory]: 'ERRORS.TECHNICAL_DIFFICULTIES.LOAD_RACE_ETHNICITY_DATA',
};

export enum SystemErrorCode {
  // External systems
  Core = 'CORE_FAIL',
  Hap = 'HAP_FAIL',
  Grid = 'GRD_FAIL',
  Esb = 'ESB_FAIL',
  Cm = 'CM_FAIL',
  EsbMock = 'EBS_MOCK_FAIL',
  Nmsm = 'NMSM_FAIL',

  // Internal systems
  Orders = 'ORDERS_FAIL',
  Subjects = 'SUBJECTS_FAIL',
  SearchMatch = 'SEARCH_MATCH_FAIL',
  Notifications = 'NOTIFICATIONS_FAIL',
  Documents = 'DOCUMENTS_FAIL',
  Common = 'COMMON_FAIL',
}

export interface BasicErrorHttpResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  message: string;
  status: number;
  statusText: string;
  url?: string;
}

export interface DiagnosticsData {
  networkCheckHttpResponse?: number;
  retryAttempted: boolean;
  retryHttpResponse?: number;
}

export interface BasicError<T = unknown> {
  traceId?: string;
  errorCode?: ClientErrorCode;
  originalError?: T;
  errorHttpResponse?: BasicErrorHttpResponse;
  diagnosticsData?: DiagnosticsData;
}

export interface ErrorDetails {
  errorCode: ClientErrorCode;
}

export type ConfigBySystemErrorCode = Partial<Record<SystemErrorCode, ErrorDetails>>;

export interface CustomErrorHandlingHttpContextModel {
  bySystemErrorCode?: ConfigBySystemErrorCode;
  singleError?: ErrorDetails;
}

export const DEFAULT_ERROR_DETAILS: ErrorDetails = {
  errorCode: ClientErrorCode.Default,
};

export type ErrorDialogData = BasicError;

export interface TraceInfo {
  requestUrl: string;
  traceId: string | null;
  errorCode?: ClientErrorCode;
}

export interface ResponseBodyWithTraceInfo<TResponse> {
  responseBody: TResponse;
  traceInfo: TraceInfo;
}
